import React from 'react';

const ListeningPracticePlan = () => {
    return (
        <div className="listening-practice-plan">
            <h2>三.达到B2水平的听力练习计划</h2>
            <p>为了达到B2水平，你需要：</p>
            <ul>
                <li>每天至少投入1-2小时的听力练习</li>
                <li>如果你觉得太浪费时间最后结果效果不好怎么办？那你就需要转换一下思维，每天把你拿来短视频或者单纯刷手机的时间全部拿来实践这个沉浸式方法，就当做娱乐消遣放轻松的去看YouTube视频；如果你每天有很多时间的话，（初到中级的学习者）我建议你用英语去学习新事物，可以看感兴趣的教程等等。像我现在练了大概六个月，能无字幕学习印度人的编程教学视频，我平时都是在上面学习算法和新的编程语言，偶尔才会看新闻和其他领域的视频（因为我已经看腻了）</li>
            </ul>
            <p>记住，持之以恒是成功的关键。不要一天看好几个小时，然后隔几天休息，最重要的是每天坚持，哪怕每天三十分钟都行。</p>
        </div>
    );
};

export default ListeningPracticePlan;
